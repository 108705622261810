import React, { useState } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { NewsHeader, NewsList } from '../components/news'
import Categories from "../components/categories"

const IndexPage = (props) => {

  const urlParams = new URLSearchParams(props.location.search);
  
  const news = props.data.allStrapiArticle.edges.map(x => {
    return {
      id: x.node.id,
      content: x.node.content,
      author: x.node.author,
      title: x.node.title,
      creationTime: x.node.creationTime,
      image: x.node.image,
      category: x.node.articles_category?.slug,
      tags: x.node.article_tags.map(t => t.slug),
    }
  })

  const categories = props.data.allStrapiArticlesCategory.edges.map(x => {
    return {
      name: x.node.name,
      slug: x.node.slug
    }
  })

  const tags = props.data.allStrapiArticleTag.edges.map(x => {
    return {
      name: x.node.name,
      slug: x.node.slug
    }
  })

  const years = [...new Set(news.map(x => new Date(x.creationTime).getFullYear()))].sort().reverse();

  const [categoryFilter, setCategoryFilter] = useState(urlParams.get('category'));
  const [yearFilter, setYearFilter] = useState(Math.max(...years));
  const [tagFilter, setTagFilter] = useState(urlParams.get('tag'));

  function filterByCategory(category) {
    setCategoryFilter(categoryFilter => category)
  }

  function filterByYear(year) {
    if (year === yearFilter) {
      setYearFilter(yearFilter => null)
    }
    else setYearFilter(yearFilter => year)
  }

  function filterByTag(tag) {
    setTagFilter(tagFilter => tag)
  }

  let filteredNews = news
    .filter(x => !categoryFilter || x.category === categoryFilter)
    .filter(x => !yearFilter || new Date(x.creationTime).getFullYear() === yearFilter)
    .filter(x => !tagFilter || x.tags.includes(tagFilter));

  return (
    <Layout>
      <SEO title="News" />
      <div className="mgt-medium page-content column">
        <div className="column">
          <NewsHeader name="Posts by Year"
            categories={categories} filterByCategory={filterByCategory} activeCategory={categoryFilter}
            years={years} filterByYear={filterByYear} activeYear={yearFilter} />
          <Categories Categories={tags} chosenCategory={tagFilter} filterByCategory={filterByTag} />
        </div>
        <NewsList classes="mt-4" isLarge={true} titleClass="is-2" contentClass="is-size-4" news={filteredNews} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`  
  query {
    allStrapiArticle {
      edges {
        node {
          id
          content
          author
          title
          creationTime
          image {
            childImageSharp {
              fluid(quality:100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          articles_category {
            name
            slug
          }  
          article_tags{
            name
            slug
          }        
        }
      }
    }
    allStrapiArticleTag {
      edges {
        node {
          name
          slug
        }
      }
    }
    allStrapiArticlesCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`